<template>
  <section v-if="sponsor">
    <v-container fill-height class="inbox" v-if="!loading">
      <v-row>
        <v-col cols="12" xl="10">
          <v-row no-gutters>
            <v-col cols="12" lg="3">
              <v-sheet outlined>
                <div class="success white--text d-flex px-3 justify-space-between align-center">
                  <div class="ma-2 font-weight-bold"
                  :class="small?'body-1' : 'text-h6'">Chat Now</div>
                  <v-btn icon color="#fff">
                    <v-icon size="18"> mdi-dots-vertical </v-icon>
                  </v-btn>
                </div>
                <v-divider></v-divider>
                <div class="mx-5">
                  <v-text-field placeholder="Search name" v-on:input="search($event)"/>
                </div>
                <Contacts :data="chat" @open="openConvo" :small="small"/>
                <!-- <infinite @distance="1" @infinite="handleLoadMore"></infinite> -->
              </v-sheet>
            </v-col>
            <v-col cols="12" lg="9" v-if="on_chat">
              <v-sheet outlined>
                <v-sheet class="d-flex pa-5 align-center">
                  <v-avatar size="80" color="grey">
                    <v-img v-if="convo.image" :src="`${convo.aws}/users/${convo.uuid}/image/${convo.image.image}`"/>
                  </v-avatar>
                  <v-list-item-content class="pl-5">
                    <v-list-item-title class="text-h6 font-weight-bold">
                      {{ convo.firstname + " " + convo.lastname }}
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      <!-- {{ convo.email }} -->
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-sheet>
                <v-divider></v-divider>
                <v-sheet id="chatdrawer" color="transparent" class="inbox__container">
                  <v-sheet class="pr-5">
                    <ChatBox 
                    :convo="convo" 
                    :user="user" 
                    :small="small" 
                    type="teacher"/>
                  </v-sheet>
                </v-sheet>
                <div class="ma-2">
                  <Input @send="sendMsg" />
                </div>
              </v-sheet>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
    <div class="body-1" v-else>Loading...</div>
  </section>
  <section v-else>
    <div class="body-1">This feature is only for sponsor account.</div>
  </section>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import Contacts from "../components/inbox/TeacherContacts.vue";
import ChatBox from "../components/inbox/ChatBox.vue";
import Input from "../components/inbox/Input.vue";
// import io from "socket.io-client";
// import infinite from 'vue-infinite-loading'
import Pusher from 'pusher-js'

export default {
  components: {
    Contacts,
    ChatBox,
    Input,
    // infinite
  },
  data() {
    return {
      loading: true,
      msg: "",
      on_chat: false,
      data: [],
      load_onScroll: false,
      mate: '',
      page: 1,
      //socket creds
      socket: "",
      sponsor: false,
      small: false,
      first: true,
      text: ''
    };
  },
  computed: {
    ...mapState("teacher", {
      chat: (state) => state.chat, //contacts
      convo: (state) => state.convo, //
      user: (state) => state.user,
    }),
  },
  mounted() {
    let vm = this

    if(vm.user.type == 'Sponsor') vm.sponsor = true
    
    // let iochat = `${this.user.uuid}:${this.$helpers.tenant()}-chat-channel:App\\Events\\ChatEvent`

    // this.socket = io.connect(this.$helpers.socket_end_point(), {});
    
    // this.socket.on("connect", () => {console.log("start connection");});
    
    // this.socket.on(iochat, function (message) {
    //   vm.getChatAction({
    //     query: this.text
    //   })
    //   if(vm.on_chat) {
    //     if(vm.mate != message.data.from) return
    //     vm.showChatAction(message.data.from).then(() => {
    //       vm.scrollBottom()
    //     });
    //   }
      
    // })
    
    this.getChatAction({
        query: this.text
      }).then(() => {
      this.loading = false;
    });

    this.subscribe()
  },
  beforeDestroy() {
    this.on_chat = false
    this.chatMutation([]);
    // this.socket.disconnect();
  },
  methods: {
    ...mapActions("teacher", ["getChatAction", "showChatAction", "sendChatAction"]),
    ...mapMutations("teacher", ["chatMutation"]),

    openConvo(e) {
      this.mate = e
      this.showChatAction(e).then(() => {
        this.on_chat = true
        this.scrollBottom()
      });
    },

    sendMsg(e) {
      
      this.sendChatAction({
        uuid: this.convo.uuid,
        message: e,
      }).then(() => {
        this.first = true
        this.scrollBottom()
      }) 
    
    }, 

    scrollBottom() {
      if(this.first) {
        setTimeout(() => {
          this.container = this.$el.querySelector("#chatdrawer");
          this.container.scrollTop = this.container.scrollHeight;
        }, 1000);

        this.first = false
        return 
      }

      this.container.scrollTop = this.container.scrollHeight;
    },

    search(e) {
      this.text = e
      this.getChatAction({
        query: this.text
      })
      console.log(e);
    },

     subscribe() {
      let vm = this
      let pusher = new Pusher('b16ba45013da47f593a8', { 
        cluster: 'ap1',
        encrypted: false
      })
      console.log(pusher);
      //Chat Channel
      let chat = pusher.subscribe(`1-chat-channel`)
      chat.bind('App\\Events\\ChatEvent', data => {
        const res = JSON.parse(JSON.stringify(data))
        // console.log(JSON.parse(res));
        vm.getChatAction({
         query: this.text
       })
        if(vm.on_chat) {
          if(vm.mate != res.data.from) return
          vm.showChatAction(res.data.from).then(() => {
            vm.scrollBottom()
          });
        }
      })
    }

    // handleLoadMore($state) {
    //   // this.load_onScroll = true
      
    //   this.getChatAction({
    //     page: this.page
    //   }).then(() => {
    //      $state.loaded()
    //     this.chat.data.forEach(ele => {
    //       this.data.push(ele)
    //     });
    //   })

    //   this.page+=1
    // }
  },
};
</script>

<style></style>
