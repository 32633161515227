<template>
  <v-sheet class="inbox__container" id="list">
    <v-list color="transparent" >
      <div v-for="(item, i) in data" :key="i">
        <v-divider v-if="i>0"></v-divider>
        <v-list-item link @click="convo(item.user.uuid)">
          <v-list-item-avatar v-if="!small">
            <v-avatar size="65" color="grey">
              <v-img height="50" width="50" v-if="item.user.image" :src="`${item.user.aws}/${item.user.model}/${item.user.uuid}/image/${item.user.image.image}`"/>
            </v-avatar>

          </v-list-item-avatar>
          <v-list-item-content>
            <div class="grey--text text-right" 
            style="margin-bottom: -5px; font-size: 12px" >
              {{$helpers.dateDiff(item.updated_at)}}
            </div>
            <v-list-item-title class="d-flex flex-wrap align-center pt-1">
              <div class="font-weight-bold">
                {{item.user.firstname +' '+item.user.lastname}} 
              <!-- {{item.user.lastname.length > 5 ? '...' : ''}} -->
              </div> 
              <div class="ml-3" v-if="item.unread">
                <v-badge :content="item.unread" inline color="success"/>
                <!-- <span class="inbox__badge" v-if="item.unread">{{item.unread}}</span> -->
              </div>
            </v-list-item-title>
            <!-- <v-list-item-subtitle class="caption">
              {{item.user.email}}
            </v-list-item-subtitle> -->
            <v-list-item-subtitle class="mt-1" v-if="item.message"
            v-html="item.message" style="height: 20px">
              <!-- {{item.recent.message.slice(0, 100)}}
              {{item.recent.message.length > 100 ? '...' : '' }} -->
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </div>
    </v-list>
    
  </v-sheet>
</template>

<script>
export default {
  props: ['data', 'small'],
  methods: {
    convo(uuid) {
      this.$emit('open', uuid)
    }
  },

  mounted() {
    // let i = 1;
    // const elem = document.querySelector('#list')
    // console.log(elem);
    // elem.addEventListener('scroll',() => {
    //   console.log('scrolling');
    //   if(elem.scrollTop + elem.clientHeight >= elem.scrollHeight) {
    //     console.log(true);
    //     this.$emit('scroll', i+=1)
    //   }
    // })
  }
}
</script>
